<template>
    <div class="login_container">
        <div class="login_box">
            <!-- <div class="avatar_box">
                <img src="../assets/logo.png" alt="" />
            </div> -->
            <div class="login_title">忘记密码</div>
               
            
            <el-tabs class="login_tabs">
                
                    <!--引用对象，loginFormRef表单的实力对象-->
                    <el-form ref="loginForm1Ref" :model="loginForm1" :rules="loginForm1Rules"   label-width="0px" class="login_form">
                        <!--prefix-icon="iconfont icon-xingmingyonghumingnicheng"-->
                        <el-form-item prop="phone">
                            <el-input v-model="loginForm1.phone" prefix-icon="el-icon-user" placeholder="请输入手机号"></el-input>
                            
                        </el-form-item>
                        <el-row>
                            <el-col :span="13">
                                <el-form-item prop="captcha">
                                    <el-input v-model="loginForm1.captcha" prefix-icon="el-icon-key" placeholder="请输入验证码"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="11">
                                <el-form-item>
                                    <el-button type="primary" @click="getCode" class="getcodebtn" :disabled="codeDisabled">{{codeMsg}}</el-button>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-form-item prop="password">
                            <el-input v-model="loginForm1.password" prefix-icon="el-icon-lock" type="password" placeholder="请输入新密码"></el-input>
                        </el-form-item>
                        <el-form-item prop="password2">
                            <el-input v-model="loginForm1.password2" prefix-icon="el-icon-lock" type="password" placeholder="请再次输入新密码"></el-input>
                        </el-form-item>

                        <el-form-item class="forgot_password">
                            <el-link type="primary" href="#/login">返回登录？</el-link>
                        </el-form-item>

                        <el-form-item class="btns">
                                <el-button type="primary" @click="login1" style="width: 360px">确认并返回登录</el-button>
                        
                            
                            <!-- <el-button type="info" @click="resetLoginForm">重置</el-button> -->
                        </el-form-item>
                    </el-form>
                
                
            </el-tabs>
            
        </div>
    </div>
</template>

<script>
export default {
    data(){
        var validatePhone = (rule,value,callback)=>{
            if(value == ''){
                callback(new Error('请输入手机号'))
            }else{
                if(value !==''){
                    var reg = /^1[13456789]\d{9}$/;
                    if(!reg.test(value)){
                        callback(new Error('手机号输入错误'))
                        this.codeDisabled = true;
                    }else{
                        //手机号码输入正确时才可点击获取验证码按钮
                        this.codeDisabled = false;
                    }
                }
                callback();
            }
                
        }
        var validatePass2 = (rule, value, callback) => {
          if (value === '') {
              callback(new Error('请再次输入密码'));
          } else if (value !== this.loginForm1.password) {
              callback(new Error('两次输入密码不一致!'));
          } else {
              callback();
          }
        }
        return{
            //这是登录表单的数据绑定对象
            loginForm1:{
                phone:'',
                captcha:'',
                password:'',
                password2:'',
            },
            //验证规则
            loginForm1Rules:{
                phone:[
                    {validator:validatePhone,trigger:"change"}
                ],
                captcha:[
                    { required: true, message: '请输入验证码', trigger: 'blur' },
                    // { min: 6, max: 6, message: '长度为6位数字', trigger: 'blur' },
                    // { type: 'number', message: '长度为6位数字', trigger: 'blur' }
                ],
                password:[
                    { required: true, message: '请输入密码', trigger: 'blur' },
                    { min: 6, max: 20, message: '长度在 6 到 20 个字符', trigger: 'blur' }
                ],
                password2:[
                    { required: true, message: '请输入确认密码', trigger: 'blur' },
                    { min: 6, max: 20, message: '长度在 6 到 20 个字符', trigger: 'blur' },
                    { validator: validatePass2, trigger: 'blur', required: true }
                ]
            },


            timer:null,
            countdown:60,
            codeMsg:'获取短信验证码',
		    codeDisabled:true,
        };
    },
    methods:{
        //清空表单
    //    resetLoginForm1(){
    //        //console.log(this)
    //        this.$refs.loginForm1Ref.resetFields();
    //    },
       login1(){
           this.$refs.loginForm1Ref.validate(async valid=>{
               //console.log(valid)
               if(!valid) return;
               const {data:res} = await this.$http.put("sys/user/updatePassword",this.loginForm1);
                console.log("找回密码成功之前");
               console.log(res);//如果某个方法返回promise，可以用 await async 简化 
               if(res.code !== 200) return this.$message.error("找回密码失败");
               this.$message.success("找回密码成功");
               //将登录成功之后的token，保存到客户端的sessionStroage中
               //项目中除了登录之外的其他api接口，必须在登录之后才能访问
               //token只应在当前网站打开期间生效，所以token保存在sessionStroage中
               console.log("找回密码成功之后");
               //console.log('找回密码页面的token'+res.result.token);
            //    window.sessionStorage.setItem('token',res.result.token);//浏览器工具 application -- Storage --  sessionStorage
            //    window.sessionStorage.setItem('uid',res.result.userInfo.id);
            //    window.sessionStorage.setItem('userInfo',JSON.stringify(res.result.userInfo));
               //通过编程式导航跳转到后台主页，路由地址是 ／home
               this.$router.push('/login');

           })
       },
       async getCode(){
            if(this.loginForm1.phone !=='' ){
                this.codeDisabled = true;
                
                const {data:res} = await this.$http.post('sys/sms?phone='+this.loginForm1.phone+'&type='+"2");
                console.log("-------getCode-------");
                console.log(res);
                if(res.code == 200){
                    if(!this.timer){
                        let _that = this;
                        _that.timer = setInterval(()=>{                        
                            if(_that.countdown>0&& _that.countdown<=60){
                                _that.countdown--;
                                if(_that.countdown !== 0){
                                    _that.codeDisabled = true;
                                    _that.codeMsg = '重新发送('+_that.countdown+')';
                                }else{
                                    clearInterval(_that.timer);
                                    _that.codeMsg  = "获取短信验证码";
                                    _that.countdown  = 60;
                                    _that.timer = null;
                                    _that.codeDisabled  = false;
                                }
                            }
                        },1000)
                    }
                    return this.$message.success('验证码已发送,5分钟内有效');
                }else{
                    this.codeDisabled  = false;
                    return this.$message.error('获取验证码失败');
                }

            }
	    },

    }

};//分号一定要写
</script>  

<style lang="less" scoped>
//需要安装less loader 以及 less
.login_container{
    // background-color: #2b4b6b;
    height: 100%;
    background-image: url('../assets/images/login/bj.jpg');
    background-position: center bottom;
    background-size: cover;
    background-repeat: no-repeat;
}
.login_box{
    width: 400px;
    height: 480px;
    background-color: #fff;
    border-radius: 3px;
    position: absolute;//绝对定位
    left:50%;
    top:50%;
    transform: translate(-50%,-50%);//位移 自身的50%

    //less 语法嵌套
    .avatar_box{
        height: 100px;
        width: 100px;
        border: 1px solid #eee;
        border-radius: 50%;//圆形边框
        padding:10px;//内边距
        box-shadow: 0 0 10px #ddd;//阴影 扩散10像素
        position: absolute;//绝对定位
        left:50%;
        transform: translate(-50%,-50%);//位移 自身的50%
        background-color: #fff;//移动上去后透明的不好看
        img{
            height: 100%;
            width: 100%;
            border-radius: 50%;
            background-color: #eee;
        }
    }
    //less 语法嵌套
    .login_title{
        position: absolute;//绝对定位
        top:15%;
        left:50%;
        transform: translate(-50%,-50%);//位移 自身的50%
        font-size: 24px;
        letter-spacing:2px;
        font-weight:bold;
        
    }
}
.login_tabs{
    position: absolute;
    top: 15%;
    width: 100%;
    padding: 0 20px;
    box-sizing: border-box;//包括内容，内边距和边框，但不包括外边距
}
.login_form{
    padding-top: 20px;
}

.btns{
    width: 100%;
    display: flex;
    //justify-content: flex-end;
    justify-content:center;
}
.getcodebtn{
    margin-left:20px;
}
.forgot_password{
    width: 100%;
    display: flex;
    //justify-content: flex-end;
    justify-content:flex-end;
}
</style>